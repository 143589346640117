import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';

import Form from '../../forms/form/Form';
import Input from '../../forms/input/Input';
import DatePickerInput from '../../forms/DatePicker/DatePicker';
import styles from './PersonalInfo.module.scss';
import validationSchema from '../../../utilities/validationschema';
import pregnancyStatuses from '../../../data/pregnancy-statuses';
import Radio from '../../forms/radio/Radio';
import { clinicState } from '../../../globalState';
import Select from '../../forms/select/Select';
import Button from '../../forms/Button/Button';
import Background from '../../Background/Background';
import ArrowLeft from '../../../assets/images/ArrowLeft.svg';
import { useNavigate } from 'react-router-dom';

const PersonalInfo = ({ handleSubmit, personalDetails, types, loading, setCurrentPage }) => {
  const { clinic } = useRecoilValue(clinicState);  
  const [selectedDate, setSelectedDate] = useState('');
  const navigate = useNavigate();

  const handleBackClick = () => {
    let linkParam = new URLSearchParams(window.location.search).get('link');
    const email = personalDetails?.email || formState.email;
  
    if (linkParam) {
      linkParam = linkParam.replace(/([?&])email=[^&]*/, '').replace(/[?&]$/, '');
    }
  
    const linkWithEmail = linkParam
      ? `${linkParam}${linkParam.includes('?') ? '&' : '?'}email=${encodeURIComponent(email)}`
      : null;
  
    linkWithEmail ? window.open(linkWithEmail, "_self") : navigate(-1);
  };
  
  const handleDateSelect = (dateString) => {
    setSelectedDate(dateString);
  };

  const [formState, setFormState] = useState({
    firstName: personalDetails?.firstname || '',
    lastName: personalDetails?.lastname || '',
    phone: personalDetails?.phone_no || '',
    email: personalDetails?.email || '',
    clinician: '',
    pregnancyStatus: ''
  });

  const handleInputChange = (name, value) => {
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const isFormComplete = () => {
    const { firstName, lastName, phone, email, clinician, pregnancyStatus } = formState;

    const isValidName = (name) => name && /^[a-zA-Z]+([-'\s]?[a-zA-Z]+)*$/.test(name);
    const isValidPhone = (phone) => phone && /^\d{10}$/.test(phone);
    const isValidEmail = (email) => email && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

    const isValidDate = (date) => {
      if (!date) return false;

      const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
      if (!dateRegex.test(date)) return false;

      const [month, day, year] = date.split('/').map(Number);
      const selectedDate = new Date(year, month - 1, day);

      const today = new Date();
      const minDate = new Date(1900, 0, 1);

      return selectedDate >= minDate && selectedDate <= today;
    };

    const isValidClinician = (clinician) => {
      if (types === 'PHQ9-GAD7') {
        return clinician && clinician.length > 0; 
      }
      return true;
    };

    const isValidPregnancyStatus = (pregnancyStatus) => {
      if (types === 'EPDS') {
        return pregnancyStatus && pregnancyStatus.length > 0;
      }
      return true;
    };

    const baseValidation =
      isValidName(firstName) &&
      isValidName(lastName) &&
      isValidPhone(phone) &&
      isValidDate(selectedDate) &&
      (isValidEmail(email) || personalDetails?.email);

    if (types === 'EPDS') {
      return baseValidation && isValidPregnancyStatus(pregnancyStatus);
    } else if (clinic?.clinicans?.length) {
      return baseValidation && isValidClinician(clinician);
    } else {
      return baseValidation;
    }
  };

  return (
    <Background clinicDetails={clinic}>
      <div className={styles.container}>
        <div className={styles.contents}>
        {personalDetails?.email && (
          <img onClick={handleBackClick} src={ArrowLeft} alt="Back Icon" className={styles.backArrow} preview={false} />
        )}
          <h1 className={styles.heading}>Personal Information</h1>
          <p className={styles.para}>Please provide your personal information to proceed.</p>

          <Form onSubmit={handleSubmit} validateSchema={validationSchema}>
            {(renderProps) => (
              <>
                <div className={styles.section}>
                  <Input
                    name="firstName"
                    label="First Name"
                    type="text"
                    defaultValue={formState.firstName}
                    autofocus={true}
                    schema={validationSchema['firstName']}
                    onChange={(e) => handleInputChange('firstName', e.target.value)}
                  />
                  <Input
                    name="lastName"
                    label="Last Name"
                    defaultValue={formState.lastName}
                    type="text"
                    schema={validationSchema['lastName']}
                    onChange={(e) => handleInputChange('lastName', e.target.value)}
                  />
                </div>
                <div className={styles.section}>
                  <Input
                    name="phone"
                    label="Phone"
                    type="text"
                    defaultValue={formState.phone}
                    schema={validationSchema['phone']}
                    onChange={(e) => handleInputChange('phone', e.target.value)}
                  />
                  <DatePickerInput
                    dateFormat="MM/DD/YYYY"
                    name="dob"
                    defaultValue={formState.dob}
                    label="Date of Birth"
                    type="date"
                    schema={validationSchema['dob']}
                    onSelect={handleDateSelect}
                  />
                </div>

                {!personalDetails?.email && (
                  <div className={styles.section}>
                    <Input
                      name="email"
                      label="Email"
                      type="text"
                      defaultValue={formState.email}
                      schema={validationSchema['email']}
                      onChange={(e) => handleInputChange('email', e.target.value)}
                    />
                  </div>
                )}

                <div className={styles.section}>
                  {clinic?.clinicans?.length ? (
                    <Select
                      name="clinician"
                      label="Which clinician will you be seeing today?"
                      options={clinic.clinicans} 
                      optionLabel="Select clinician"
                      isRequired={true}
                      schema={validationSchema['clinician']}
                      onChange={(value) => handleInputChange('clinician', value)}
                    />
                  ) : null}

                  {types === 'EPDS' && (
                    <Radio
                      name="pregnancyStatus"
                      label="Are you currently?"
                      options={pregnancyStatuses}
                      schema={validationSchema['pregnancyStatus']}
                      onChange={(value) => handleInputChange('pregnancyStatus', value)} 
                    />
                  )}
                </div>

                <div className={`${styles.buttonContainer} ${!isFormComplete() ? styles.disabled : ''}`}>
                  <Button type="submit" disabled={loading || !isFormComplete()}>
                    {loading ? 'Wait...' : 'Take Assessment'}
                  </Button>
                </div>
              </>
            )}
          </Form>
        </div>
      </div>
    </Background>
  );
};

export default PersonalInfo;
